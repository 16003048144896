import React from "react"
import styled from "@emotion/styled"

import Paper from "./Paper"
import Cta from "./Cta"

const Container = styled.section`
  max-width: 130rem;
  padding: 3rem 1.5rem;
  margin: 0 auto;
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #2196f3;
  background: linear-gradient(to right, #2196f3, #2dc7ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 3rem 0;
  @media only screen and (max-width: 32.5em) {
    justify-content: flex-start;
  }
  .paper {
    width: 100%;
    margin: 1.5rem;
    max-width: 35rem;
    ul {
      max-width: 35rem;
    }
    @media only screen and (max-width: 32.5em) {
      margin: 1.5rem auto;
    }
  }
`

const Body = styled.p`
  font-size: 1.7rem;
  padding: 0.5rem 0;
  font-weight: 400;
  color: #505050;
`

const RecyclingShort = ({ data: { title, data } }) => {
  return (
    <Container>
      <Primary>{title}</Primary>
      <Content>
        {data &&
          data.map(({ name, body, image }, count) => (
            <Paper
              title={name}
              image={`/img/${image.relativePath}`}
              key={count}
            >
              <Body>{body}</Body>
            </Paper>
          ))}
      </Content>
      <Cta to="/recycling" variant>
        Learn more
      </Cta>
    </Container>
  )
}
export default RecyclingShort
