import React from "react"
import styled from "@emotion/styled"

import Paper from "./Paper"

const Container = styled.section`
  max-width: 130rem;
  margin: 0 auto;
  padding: 3rem 1.5rem;
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #2196f3;
  background: linear-gradient(to right, #2196f3, #2dc7ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Subtitle = styled.h3`
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  padding: 1.5rem 10rem;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 3rem 0;
  .paper {
    padding: 2rem 2rem;
    width: 100%;
    max-width: 35rem;
    border-right: 1px solid #cfcfcf;
    :nth-of-type(3n) {
      border-right: none;
    }
    :nth-child(-n+3) {
      background-image: linear-gradient(to right, #cfcfcf 33%, rgba(207,207,207,0) 0%);
      background-position: bottom;
      background-size: 15px 1px;
      background-repeat: repeat-x;
    }
    :last-child {
      border-right: none;
    }
    @media only screen and (max-width: 68.75em) {
      border-right: none;
      :nth-of-type(odd) {
        border-right: 1px solid #cfcfcf;
      }
      :nth-child(-n+4) {
        background-image: linear-gradient(to right, #cfcfcf 33%, rgba(207,207,207,0) 0%);
        background-position: bottom;
        background-size: 15px 1px;
        background-repeat: repeat-x;
      }
    }
    @media only screen and (max-width: 46em) {
      border-right: none !important;
      background: none !important;
    }
  }
`

const Body = styled.p`
  padding: 1rem 0;
  font-weight: 400;
  color: #505050;
`

const ListWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`

const List = styled.ul`
  text-align: left;
  list-style: none;
`

const Item = styled.li`
  color: #505050;
  padding: 0.5rem 0;
  &::before {
    content: "\u276F";
    padding-right: 5px;
    color: #2196f3;
  }
`

const Features = ({ data: { title, subtitle, data } }) => {
  return (
    <Container>
      <Primary>{title}</Primary>
      <Subtitle>{subtitle}</Subtitle>
      <Content>
        {data.map(({ name, url, image, body, data }, count) => (
          <Paper
            title={name}
            image={image.relativePath ? `/img/${image.relativePath}` : image}
            ctaTo={url}
            ctaBody="How it works"
            key={count}
          >
            <Body>{body}</Body>
            <ListWrapper>
              <List>
                {data &&
                  data.map((item, count) => <Item key={count}>{item}</Item>)}
              </List>
            </ListWrapper>
          </Paper>
        ))}
      </Content>
    </Container>
  )
}

export default Features
